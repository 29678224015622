import { SearchOutlined } from '@ant-design/icons';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Input, Pagination, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { TbSquareChevronLeft, TbSquareChevronRight } from 'react-icons/tb';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import Loader from '../components/Loader';
import FilterSection from '../components/assistant-templates/filter-section';
import TabSwitch from '../components/assistant-templates/header-tabs';
import TemplateCard from '../components/assistant-templates/template-card';
import axios from '../config/axios';
import { cn } from '../config/cn';
import AppLayout from '../layouts/app.layout';
import { Voice } from '../types/ai-assistant.types';

export type Template = {
	industry: Industry;
	voice: Voice;
	template_id: string;
	name: string;
	prompt: string;
	model: string;
	assistant_type: string;
	template_type: string;
	status: string;
	timezone: string;
	avatar: string;
	installs_count: number;
	created_at: Date;
	updated_at: Date;
};

export type Industry = {
	industry_id: string;
	name: string;
	icon: string;
	created_at: Date;
	updated_at: Date;
};

interface InstallRange {
	min_install: number | null;
	max_install: number | null;
}

const AssistantTemplates = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	// Set initial values if not present
	useEffect(() => {
		setSearchParams((prev) => {
			const newParams = new URLSearchParams(prev);
			if (!newParams.has('template_type')) {
				newParams.set('template_type', 'inbound');
			}
			if (!newParams.has('sidebar')) {
				newParams.set('sidebar', 'true');
			}
			return newParams;
		});
	}, [setSearchParams]);

	// Replace state with searchParams
	const selectedTab = searchParams.get('template_type') || 'inbound';
	const selectedIndustry = searchParams.get('industry');
	const minInstall = searchParams.get('min_install');
	const maxInstall = searchParams.get('max_install');
	const title = searchParams.get('name') || '';
	const page = parseInt(searchParams.get('page') || '1');
	const isSidebarVisible = searchParams.get('sidebar') !== 'false';

	// Keep debounce for search performance
	const [debouncedTitle, setDebouncedTitle] = useState<string>(title);

	useDebounce(
		() => {
			setDebouncedTitle(title);
		},
		500,
		[title],
	);

	const { data: industries, isLoading: isIndustriesLoading } = useQuery<Industry[]>({
		queryKey: ['industries'],
		queryFn: async () => {
			const { data } = await axios.get('/commons/industries', {
				withCredentials: true,
			});
			return data.results;
		},
	});

	const { data, isLoading: isTemplatesLoading } = useQuery({
		queryKey: ['assistant-templates', page, debouncedTitle, selectedTab, selectedIndustry, { min_install: minInstall, max_install: maxInstall }],
		queryFn: async () => {
			const { data } = await axios.get('/assistant-templates', {
				withCredentials: true,
				params: {
					page,
					limit: 20,
					name: debouncedTitle ? debouncedTitle : undefined,
					industry: selectedIndustry,
					min_install: minInstall,
					max_install: maxInstall,
					template_type: selectedTab,
				},
			});
			return data;
		},
		placeholderData: keepPreviousData,
	});

	const handleIndustryToggle = (industryId: string) => {
		if (!industryId) return;

		setSearchParams((prev) => {
			const newParams = new URLSearchParams(prev);
			if (selectedIndustry === industryId) {
				newParams.delete('industry');
			} else {
				newParams.set('industry', industryId);
			}
			return newParams;
		});
	};

	const getRangeValues = (rangeStr: string): InstallRange => {
		switch (rangeStr) {
			case '1000+':
				return { min_install: 1000, max_install: null };
			case '500-1000':
				return { min_install: 500, max_install: 1000 };
			case '100-500':
				return { min_install: 100, max_install: 500 };
			case '0-100':
				return { min_install: 0, max_install: 100 };
			default:
				return { min_install: null, max_install: null };
		}
	};

	const handleInstallToggle = (range: string) => {
		const newRange = getRangeValues(range);
		setSearchParams((prev) => {
			const newParams = new URLSearchParams(prev);
			if (
				minInstall === newRange.min_install?.toString() &&
				(newRange.max_install === null ? maxInstall === null : maxInstall === newRange.max_install?.toString())
			) {
				newParams.delete('min_install');
				newParams.delete('max_install');
			} else {
				if (newRange.min_install !== null) {
					newParams.set('min_install', newRange.min_install.toString());
				}
				if (newRange.max_install !== null) {
					newParams.set('max_install', newRange.max_install.toString());
				} else {
					newParams.delete('max_install');
				}
			}
			return newParams;
		});
	};

	const toggleSidebar = () => {
		setSearchParams((prev) => {
			const newParams = new URLSearchParams(prev);
			newParams.set('sidebar', (!isSidebarVisible).toString());
			return newParams;
		});
	};

	return (
		<AppLayout
			title="Templates"
			subtitle="Templates for AI Assistants to help you get started quickly."
		>
			<div className="flex">
				<div
					className={cn('flex w-80 items-center justify-between border-b border-r border-gray-200 p-3 text-primary', {
						'w-auto gap-4 border-r-0 text-black-7': !isSidebarVisible,
					})}
				>
					<h2 className="text-xl font-bold">Filters</h2>
					<div>
						{isSidebarVisible ? (
							<TbSquareChevronLeft
								size={20}
								onClick={toggleSidebar}
								role="button"
							/>
						) : (
							<TbSquareChevronRight
								size={20}
								onClick={toggleSidebar}
								role="button"
							/>
						)}
					</div>
				</div>
				<div className="flex-1 border-b border-gray-200 p-3">
					<div className="flex items-center justify-between">
						<div>
							<TabSwitch
								selectedTab={selectedTab}
								onTabChange={(tab) => {
									setSearchParams((prev) => {
										const newParams = new URLSearchParams(prev);
										newParams.set('template_type', tab);
										return newParams;
									});
								}}
							/>
						</div>

						<div>
							<Input
								prefix={<SearchOutlined className="text-gray-400" />}
								placeholder="Search by name"
								className="h-11 w-64"
								value={title}
								onChange={(e) => {
									setSearchParams((prev) => {
										const newParams = new URLSearchParams(prev);
										if (e.target.value) {
											newParams.set('name', e.target.value);
										} else {
											newParams.delete('name');
										}
										return newParams;
									});
								}}
								allowClear
								onClear={() => {
									setSearchParams((prev) => {
										const newParams = new URLSearchParams(prev);
										newParams.delete('name');
										return newParams;
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="flex">
				{isSidebarVisible && (
					<div className="w-80 border-r border-gray-200 p-3">
						{(selectedIndustry || title || minInstall || maxInstall) && (
							<div
								className="mb-3 text-base font-semibold text-primary"
								onClick={() => {
									setSearchParams((prev) => {
										const newParams = new URLSearchParams(prev);
										newParams.delete('industry');
										newParams.delete('min_install');
										newParams.delete('max_install');
										newParams.delete('name');
										return newParams;
									});
								}}
								role="button"
							>
								Clear All Filters
							</div>
						)}

						<FilterSection title="Installs">
							<div className="grid grid-cols-2 gap-2">
								{['1000+', '500-1000', '100-500', '0-100'].map((range, index) => {
									const rangeValues = getRangeValues(range);
									return (
										<div
											key={index}
											className={cn(`flex items-center rounded border border-gray-200 px-4 py-1.5 font-semibold`, {
												'border-primary/40 bg-primary/20 text-primary':
													minInstall === rangeValues.min_install?.toString() &&
													(rangeValues.max_install === null
														? maxInstall === null
														: maxInstall === rangeValues.max_install?.toString()),
											})}
											onClick={() => handleInstallToggle(range)}
											role="button"
										>
											{range}
										</div>
									);
								})}
							</div>
						</FilterSection>

						<FilterSection title="Industries">
							{isIndustriesLoading && (
								<div className="grid grid-cols-2 gap-2">
									{[...Array(6)].map((_, index) => (
										<Skeleton.Button
											key={index}
											block
											active
											className="h-9"
										/>
									))}
								</div>
							)}
							{industries && industries.length > 0 && (
								<div className="grid grid-cols-2 gap-2">
									{industries.map((industry, index) => (
										<div
											key={index}
											className={cn(
												`flex items-center justify-center rounded border border-gray-200 px-4 py-1.5 font-semibold`,
												{
													'border-primary/40 bg-primary/20 text-primary': selectedIndustry === industry.industry_id,
												},
											)}
											onClick={() => handleIndustryToggle(industry.industry_id)}
											role="button"
										>
											{industry.name}
										</div>
									))}
								</div>
							)}
						</FilterSection>
					</div>
				)}
				<div className="min-h-[calc(100dvh-11.8rem)] flex-1 p-3">
					{isTemplatesLoading && (
						<div className="flex h-full items-center justify-center">
							<Loader />
						</div>
					)}

					{data && data.results.length === 0 && (
						<div className="flex h-full flex-col items-center justify-center gap-2">
							<img
								src="/images/templates/no-templates.svg"
								alt="No Templates"
								className="size-40"
							/>
							<div className="text-center text-lg font-semibold text-primary">No templates found.</div>
							<div className="text-center text-sm text-gray-500">Try different filters or search for a different keyword.</div>
						</div>
					)}

					{data && data.results.length > 0 && (
						<div>
							<div
								className={cn(`grid grid-cols-1 md:grid-cols-2`, {
									'lg:grid-cols-3': isSidebarVisible,
									'lg:grid-cols-4': !isSidebarVisible,
								})}
							>
								{data.results.map((template: Template) => (
									<TemplateCard
										key={template.template_id}
										template={template}
									/>
								))}
							</div>

							<div className="pt-4" />

							<div className="flex items-center justify-center">
								<Pagination
									current={page}
									total={data.count}
									pageSize={20}
									showSizeChanger={false}
									onChange={(newPage) => {
										setSearchParams((prev) => {
											const newParams = new URLSearchParams(prev);
											newParams.set('page', newPage.toString());
											return newParams;
										});
									}}
									showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</AppLayout>
	);
};

export default AssistantTemplates;
