import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Modal, Skeleton } from 'antd';
import { useState } from 'react';
import axios from '../../config/axios';
import AddPaymentMethod from './add-payment-method';
import PaymentCard from './payment-card';

export type Card = {
	id: string;
	object: string;
	allow_redisplay: string;
	billing_details: BillingDetails;
	card: CardClass;
	created: number;
	customer: string;
	livemode: boolean;
	metadata: Metadata;
	type: string;
};

export type BillingDetails = {
	address: Address;
	email: string;
	name: string;
	phone: null;
};

export type Address = {
	city: null;
	country: string;
	line1: null;
	line2: null;
	postal_code: null;
	state: null;
};

export type CardClass = {
	brand: string;
	checks: Checks;
	country: string;
	display_brand: string;
	exp_month: number;
	exp_year: number;
	fingerprint: string;
	funding: string;
	generated_from: null;
	last4: string;
	networks: Networks;
	three_d_secure_usage: ThreeDSecureUsage;
	wallet: null;
};

export type Checks = {
	address_line1_check: null;
	address_postal_code_check: null;
	cvc_check: string;
};

export type Networks = {
	available: string[];
	preferred: null;
};

export type ThreeDSecureUsage = {
	supported: boolean;
};

export type Metadata = object;

const PaymentMethods = () => {
	const [showAddPaymentMethod, setShowAddPaymentMethod] = useState<boolean>(false);

	// get all payment methods
	const { data, isLoading } = useQuery<Card[]>({
		queryKey: ['payment-methods'],
		queryFn: async () => {
			const { data } = await axios.get('/billing/payment-methods', { withCredentials: true });
			return data.results;
		},
	});

	return (
		<div className="mx-auto max-w-screen-sm px-4">
			{isLoading && (
				<div className="grid grid-cols-1 gap-4">
					{Array.from({ length: 6 }).map((_, index) => (
						<Skeleton.Node
							key={index}
							active
							fullSize
							style={{
								height: '4rem',
								width: '100%',
							}}
						>
							<div>{null}</div>
						</Skeleton.Node>
					))}
				</div>
			)}

			{data && data.length === 0 ? (
				<div className="flex items-center pt-2">
					<div className="text-base text-gray-700 dark:text-gray-300">No payment methods found.</div>
				</div>
			) : null}

			{data && data.length > 0 && (
				<div className="grid grid-cols-1 gap-4">
					{data.map((card, index) => (
						<PaymentCard
							card={card}
							key={index}
						/>
					))}
				</div>
			)}

			<div className="pt-6" />

			<Button
				onClick={() => setShowAddPaymentMethod(true)}
				icon={<PlusOutlined />}
				size="large"
				type="dashed"
				block
			>
				Add Payment Method
			</Button>

			<Modal
				open={showAddPaymentMethod}
				title={'Add Payment Method'}
				footer={null}
				onCancel={() => setShowAddPaymentMethod(false)}
				centered
			>
				<div className="pt-2" />
				<AddPaymentMethod />
			</Modal>

			<div className="pb-10" />
		</div>
	);
};

export default PaymentMethods;
