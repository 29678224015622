import { Button, Form, Input, Modal } from 'antd';
import axios from '../../config/axios';
import { useMutation } from '@tanstack/react-query';
import { handleAPIError } from '../../utils/helpers';

type Props = {
	open: boolean;
	close: () => void;
};

type FormValues = {
	name: string;
};

const CreateWorkspaceModal = (props: Props) => {
	const { open, close } = props;

	const { mutate: createWorkspace, isPending: isCreatingWorkspace } = useMutation({
		mutationFn: async (values: FormValues) => {
			const { data } = await axios.post('/workspaces', values, { withCredentials: true });
			return data.results;
		},
		onSuccess: (data) => {
			localStorage.setItem('default_workspace', JSON.stringify(data));

			// reload the page with base URL
			window.location.href = window.location.origin;
		},
		onError: handleAPIError,
	});

	return (
		<Modal
			open={open}
			onCancel={close}
			title="Create Workspace"
			footer={null}
			centered
			width={400}
			destroyOnClose
		>
			<Form
				layout="vertical"
				onFinish={(values) => createWorkspace(values)}
			>
				<Form.Item
					name="name"
					label={<div className="font-semibold">Workspace Name</div>}
					className="mb-4"
				>
					<Input
						required
						placeholder="Workspace Name"
						maxLength={100}
						showCount
					/>
				</Form.Item>
				<Form.Item className="mb-0 flex justify-end">
					<div className="flex gap-2">
						<Button onClick={close}>Cancel</Button>
						<Button
							type="primary"
							htmlType="submit"
							loading={isCreatingWorkspace}
							disabled={isCreatingWorkspace}
						>
							Create
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default CreateWorkspaceModal;
