import { Button, Avatar } from 'antd';
import { useState } from 'react';
import TemplateDescriptionModal from './template-description-modal';
import { Template } from '../../views/assistant-templates';

interface TemplateCardProps {
	template: Template;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template }) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<div className="overflow-hidden rounded-lg border border-gray-200 bg-white">
			<div className="p-4">
				<div className="flex items-start gap-3">
					<Avatar
						src={template.avatar || `https://avatar.iran.liara.run/public?username=${template.name}`}
						size={48}
						className="rounded-full"
					/>
					<div>
						<h3 className="text-lg font-semibold text-gray-900">{template.name}</h3>
						{template.industry && <div className="text-sm font-semibold text-gray-600">{template.industry.name}</div>}
					</div>
				</div>

				<div className="pt-4" />

				<div className="flex items-center justify-between">
					<div className="text-base text-gray-500">Total installs</div>
					<div className="font-medium text-gray-900">{template.installs_count}</div>
				</div>
				<div className="pt-1" />
				<div className="flex items-center justify-between">
					<div className="text-base text-gray-500">Created by</div>
					<div className="font-medium text-gray-900">Puretalk</div>
				</div>

				<div className="pt-4" />

				<Button
					className="font-semibold"
					block
					onClick={() => setShowModal(true)}
				>
					View Details
				</Button>
			</div>

			<TemplateDescriptionModal
				open={showModal}
				close={() => setShowModal(false)}
				template={template}
			/>
		</div>
	);
};

export default TemplateCard;
