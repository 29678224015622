import { useQuery } from '@tanstack/react-query';
import axios from '../config/axios';
import AppLayout from '../layouts/app.layout';
import { Workspace } from '../layouts/sidebar-bottom';
import Loader from '../components/Loader';
import { Table } from 'antd';
import dayjs from '../config/dayjs';
import { RiEdit2Line } from 'react-icons/ri';
import { useState } from 'react';
import UpdateWorkspaceModal from '../components/layouts/update-workspace-modal';

const Workspaces = () => {
	const [updateWorkspaceModalOpen, setUpdateWorkspaceModalOpen] = useState(false);
	const [workspaceToUpdate, setWorkspaceToUpdate] = useState<Workspace | null>(null);

	// get workspaces
	const { data, isLoading, refetch } = useQuery<Workspace[]>({
		queryKey: ['workspaces'],
		queryFn: async () => {
			const { data } = await axios.get('/workspaces', { withCredentials: true });

			return data.results;
		},
	});

	return (
		<AppLayout
			title="Workspaces"
			subtitle="Manage your workspaces"
		>
			{isLoading && (
				<div className="flex h-[calc(100dvh-8rem)] items-center justify-center">
					<Loader />
				</div>
			)}

			{data && (
				<Table
					dataSource={data}
					bordered
					pagination={false}
					rowKey={(record) => record.workspace_id}
					size="middle"
					title={() => <div className="text-xl font-bold">Workspaces</div>}
					columns={[
						{
							title: 'Name',
							dataIndex: 'name',
							key: 'name',
							render: (name: string) => <div className="font-medium">{name}</div>,
						},
						{
							title: 'Last Modified',
							dataIndex: 'updated_at',
							key: 'updated_at',
							render: (updated_at: Date) => <div className="font-medium">{dayjs(updated_at).format('DD MMM YYYY HH:mm A')}</div>,
						},
						{
							title: 'Workspace ID',
							dataIndex: 'workspace_id',
							key: 'workspace_id',
							render: (workspace_id: string) => <div className="font-medium">{workspace_id}</div>,
						},
						{
							title: 'Actions',
							dataIndex: 'actions',
							key: 'actions',
							render: (_, record: Workspace) => (
								<div
									role="button"
									onClick={() => {
										setWorkspaceToUpdate(record);
										setUpdateWorkspaceModalOpen(true);
									}}
								>
									<RiEdit2Line size={20} />
								</div>
							),
						},
					]}
				/>
			)}

			{workspaceToUpdate && (
				<UpdateWorkspaceModal
					open={updateWorkspaceModalOpen}
					close={() => setUpdateWorkspaceModalOpen(false)}
					workspace={workspaceToUpdate}
					refetch={refetch}
				/>
			)}
		</AppLayout>
	);
};

export default Workspaces;
