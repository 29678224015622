export type MenuOption = {
	name: string;
	path: string | null;
	activeIcon: string;
	inactiveIcon: string;
	children?: { name: string; path: string }[];
};

export const mainFeatures: MenuOption[] = [
	{
		name: 'AI Assistants',
		path: '/ai-assistants',
		activeIcon: '/images/layout/ai-assistant-active-icon.svg',
		inactiveIcon: '/images/layout/ai-assistant-inactive-icon.svg',
	},
	{
		name: 'Knowledge Base',
		path: '/knowledge-base',
		activeIcon: '/images/layout/knowledge-base-active-icon.svg',
		inactiveIcon: '/images/layout/knowledge-base-inactive-icon.svg',
	},
	{
		name: 'Phone Numbers',
		path: '/phone-numbers',
		activeIcon: '/images/layout/phone-numbers-active-icon.svg',
		inactiveIcon: '/images/layout/phone-numbers-inactive-icon.svg',
	},
	{
		name: 'Logs',
		path: null,
		activeIcon: '/images/layout/call-logs-active-icon.svg',
		inactiveIcon: '/images/layout/call-logs-inactive-icon.svg',
		children: [
			{
				name: 'Call Logs',
				path: '/call-logs',
			},
			{
				name: 'Chat Logs',
				path: '/chat-logs',
			},
		],
	},
	{
		name: 'Campaign',
		path: null,
		activeIcon: '/images/layout/campaign-active-icon.svg',
		inactiveIcon: '/images/layout/campaign-inactive-icon.svg',
		children: [
			{
				name: 'Manage Campaigns',
				path: '/campaigns',
			},
			{
				name: 'Create Campaign',
				path: '/create-campaign',
			},
		],
	},
	{
		name: 'Templates',
		path: '/assistant-templates',
		activeIcon: '/images/layout/templates-active-icon.svg',
		inactiveIcon: '/images/layout/templates-inactive-icon.svg',
	},
];

export const settings: MenuOption[] = [
	{
		name: 'RUTH® Connect',
		path: '/ruth-connect',
		activeIcon: '/images/layout/ruth-connect-active-icon.svg',
		inactiveIcon: '/images/layout/ruth-connect-inactive-icon.svg',
	},
	{
		name: 'Billing',
		path: '/billing',
		activeIcon: '/images/layout/billing-active-icon.svg',
		inactiveIcon: '/images/layout/billing-inactive-icon.svg',
	},
	{
		name: 'Profile',
		path: '/profile',
		activeIcon: '/images/layout/profile-active-icon.svg',
		inactiveIcon: '/images/layout/profile-inactive-icon.svg',
	},
	{
		name: 'API Keys',
		path: '/api-keys',
		activeIcon: '/images/layout/api-keys-active-icon.svg',
		inactiveIcon: '/images/layout/api-keys-inactive-icon.svg',
	},
];
