import axios from 'axios';
import { Workspace } from '../layouts/sidebar-bottom';

const baseUrl = import.meta.env.VITE_SERVER_URL;

// creating an axios instance with auth setup
const axiosInstance = axios.create({
	baseURL: baseUrl,
	timeout: 40000,
});

// adding the request interceptor
axiosInstance.interceptors.request.use((config) => {
	if (config.withCredentials) {
		const deviceId: string | null = localStorage.getItem('device_id');
		const defaultWorkspace: Workspace | null = JSON.parse(localStorage.getItem('default_workspace') ?? 'null');
		if (deviceId) {
			config.headers['Authorization'] = `Token ${deviceId}`;
		}
		if (defaultWorkspace) {
			config.headers['Workspace'] = defaultWorkspace.workspace_id;
		}
	}

	return config;
});

//defining the interceptor which will automate the process of refresh and access token
axiosInstance.interceptors.response.use(
	function (response) {
		// Simply returning the response
		return response;
	},
	function (error) {
		return Promise.reject(error);
	},
);

export default axiosInstance;
