import { Popover } from 'antd';
import { FC, useContext, useState } from 'react';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { cn } from '../config/cn';
import { SidebarContext } from '../context/sidebar.context.tsx';
import { MenuOption } from './menu-items';

type RenderMenuProps = {
	items: MenuOption[];
};

const getInitialOpenSubmenu = (items: MenuOption[]) => {
	const activeItem = items.find((item) => item.children && item.children.some((child) => window.location.pathname.includes(child.path)));
	return activeItem ? activeItem.name : null;
};

const RenderMenu: FC<RenderMenuProps> = (props) => {
	const { isCollapsed } = useContext(SidebarContext);
	const [openSubmenu, setOpenSubmenu] = useState(() => getInitialOpenSubmenu(props.items));

	const toggleSubmenu = (itemName: string) => {
		setOpenSubmenu(openSubmenu === itemName ? null : itemName);
	};

	// check if the menu item is active
	const isActive = (item: MenuOption, openSubmenu: string | null) => {
		return item.path
			? window.location.pathname.includes(item.path)
			: (item.children && item.children.some((child) => window.location.pathname.includes(child.path))) || openSubmenu === item.name;
	};

	return (
		<div className="flex flex-col">
			{props.items.map((item, index) => (
				<div
					key={index}
					className="relative"
				>
					{item.path ? (
						<Link
							to={item.path}
							key={index}
							title={item.name}
						>
							<div
								className={cn('flex items-center gap-2.5 rounded-lg px-2.5 py-2 text-black-7 transition-all duration-300', {
									'justify-center': isCollapsed,
									'bg-[#B18EED1A] text-link-active': location.pathname.includes(item.path),
									'text-black-7 dark:text-white': !location.pathname.includes(item.path),
								})}
							>
								<img
									src={item.activeIcon}
									className={cn('size-6', {
										hidden: !location.pathname.includes(item.path),
									})}
									alt="active-icon"
								/>
								<img
									src={item.inactiveIcon}
									className={cn('size-6', {
										hidden: location.pathname.includes(item.path),
									})}
									alt="inactive-icon"
								/>

								{!isCollapsed && <div className={cn('truncate text-base font-semibold')}>{item.name}</div>}
							</div>
						</Link>
					) : isCollapsed ? (
						<Popover
							content={
								<div className="flex flex-col gap-1">
									{item.children?.map((child, i) => (
										<Link
											to={child.path}
											key={i}
											title={child.name}
											className={cn('rounded-lg px-2 py-1.5', {
												'bg-[#B18EED1A] text-link-active': location.pathname.includes(child.path),
											})}
										>
											<div className={cn('truncate text-base font-semibold')}>{child.name}</div>
										</Link>
									))}
								</div>
							}
							trigger="click"
							placement="right"
							arrow={false}
							className="cursor-pointer"
						>
							<div
								className={cn('flex items-center justify-between gap-2.5 rounded-lg px-2.5 py-2 transition-all duration-300', {
									'justify-center': isCollapsed,
									'bg-[#B18EED1A]': isActive(item, openSubmenu),
									'text-link-active': isActive(item, openSubmenu),
									'text-black-7 dark:text-white': !isActive(item, openSubmenu),
								})}
							>
								<div className="flex items-center gap-2.5">
									{isActive(item, openSubmenu) ? (
										<img
											src={item.activeIcon}
											className="size-6"
											alt="active-icon"
										/>
									) : (
										<img
											src={item.inactiveIcon}
											className="size-6"
											alt="inactive-icon"
										/>
									)}
								</div>
							</div>
						</Popover>
					) : (
						<div
							onClick={() => toggleSubmenu(item.name)}
							title={item.name}
							role="button"
						>
							<div
								className={cn('flex items-center justify-between gap-2.5 rounded-lg px-2.5 py-2 transition-all duration-300', {
									'justify-center': isCollapsed,
									'bg-[#B18EED1A]': isActive(item, openSubmenu),
									'text-link-active': isActive(item, openSubmenu),
									'text-black-7 dark:text-white': !isActive(item, openSubmenu),
								})}
							>
								<div className="flex items-center gap-2.5">
									{isActive(item, openSubmenu) ? (
										<img
											src={item.activeIcon}
											className="size-6"
											alt="active-icon"
										/>
									) : (
										<img
											src={item.inactiveIcon}
											className="size-6"
											alt="inactive-icon"
										/>
									)}
									{!isCollapsed && <div className={cn('truncate text-base font-semibold')}>{item.name}</div>}
								</div>

								{!isCollapsed && <div>{openSubmenu === item.name ? <GoChevronUp size={20} /> : <GoChevronDown size={20} />}</div>}
							</div>
						</div>
					)}

					{!isCollapsed && item.children && openSubmenu === item.name && (
						<div className="flex flex-col gap-1 pl-6 pt-2">
							{item.children.map((child, i) => (
								<Link
									to={child.path}
									key={i}
									title={child.name}
									className={cn('rounded-lg px-2 py-1.5', {
										'bg-[#B18EED1A] text-link-active': location.pathname.includes(child.path),
									})}
								>
									<div className={cn('truncate text-base font-semibold')}>{child.name}</div>
								</Link>
							))}
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default RenderMenu;
