import { Avatar, Button, Divider, Form, Input, Spin } from 'antd';
import { AxiosError } from 'axios';
import { ChangeEvent, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { FaGithub } from 'react-icons/fa6';
import { FcGoogle } from 'react-icons/fc';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoTrashOutline } from 'react-icons/io5';
import { RiImageEditLine } from 'react-icons/ri';
import axios from '../config/axios';
import dayjs from '../config/dayjs';
import { AuthContext } from '../context/auth.context.tsx';
import AppLayout from '../layouts/app.layout';
import { colors } from '../theme/colors';
import { User } from '../types/user.types';

const Profile = () => {
	// const [showDeleteModal, setShowDeleteModal] = useState(false);
	// const [allowDelete, setAllowDelete] = useState(false);
	const { user, setUser } = useContext(AuthContext);
	const [loading, setLoading] = useState({
		deleteDP: false,
		updateDP: false,
		updateName: false,
		updatePassword: false,
	});
	const [nameForm] = Form.useForm();
	const [changePasswordForm] = Form.useForm();

	const updateUserContext = (key: string, value: string | null) => {
		setUser((prev) => ({
			...(prev as User),
			[key]: value,
		}));
	};

	const updateProfileKey = async (key: string, value: string | null) => {
		await axios.patch(
			'/users/edit-profile',
			{
				[key]: value,
			},
			{
				withCredentials: true,
			},
		);
	};

	// handle delete profile picture
	const handleDeleteDp = async () => {
		try {
			setLoading({ ...loading, deleteDP: true });
			// delete dp
			await updateProfileKey('dp', null);

			toast.success('Profile picture deleted successfully.');

			// update user context
			updateUserContext('dp', null);
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('Failed to delete profile picture.');
			}
		} finally {
			setLoading({ ...loading, deleteDP: false });
		}
	};

	// handle upload dp
	const handleUploadDp = async (e: ChangeEvent<HTMLInputElement>) => {
		try {
			setLoading({ ...loading, updateDP: true });
			const file = e.target.files && e.target.files[0];
			const formData = new FormData();
			formData.append('image', file as File);

			// upload image
			const { data } = await axios.post('/commons/upload-image', formData, { withCredentials: true });

			// update dp
			await updateProfileKey('dp', data.results.secure_url);

			toast.success('Profile picture updated successfully.');

			// update user context
			updateUserContext('dp', data.results.secure_url);
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('Failed to upload profile picture.');
			}
		} finally {
			setLoading({ ...loading, updateDP: false });
		}
	};

	// handle update name
	const handleUpdateName = async (values: { name: string }) => {
		try {
			setLoading({ ...loading, updateName: true });
			// update name
			await updateProfileKey('name', values.name);

			toast.success('Name updated successfully.');

			// update user context
			updateUserContext('name', values.name);
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('Failed to update name.');
			}
		} finally {
			setLoading({ ...loading, updateName: false });
		}
	};

	// handle change password
	const handleChangePassword = async (values: { old_password: string; new_password: string }) => {
		try {
			setLoading({ ...loading, updatePassword: true });
			// change password
			await axios.post(
				'/users/change-password',
				{
					...values,
				},
				{
					withCredentials: true,
				},
			);

			toast.success('Password updated successfully.');

			// update user context
			updateUserContext('last_password_reset', dayjs().format());
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				const { status, data } = error.response;
				switch (status) {
					case 429:
						return toast.error(data.detail);
					case 404:
						return toast.error(data.msg);
					default:
						return toast.error('Internal server error');
				}
			}
		} finally {
			setLoading({ ...loading, updatePassword: false });
		}
	};

	return (
		<AppLayout
			title="Profile"
			subtitle="Update your personal information and security settings."
		>
			<div className="pt-6" />

			<div className={'grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-0'}>
				<div className={'flex flex-col gap-1'}>
					<div className="flex items-center gap-2">
						<div className={'text-xl font-bold text-black-7 dark:text-white'}>Avatar</div>
						{loading.deleteDP || loading.updateDP ? <Spin size="small" /> : null}
					</div>

					<div className={'font-medium text-black-3 dark:text-[#8a8990]'}>Edit your profile picture</div>
				</div>
				<div>
					<div className={'relative w-fit'}>
						{user?.dp ? (
							<Avatar
								src={user.dp}
								size={100}
							/>
						) : (
							<Avatar size={100}>
								<span className={'text-4xl'}>{user?.name[0].toUpperCase()}</span>
							</Avatar>
						)}

						<label
							htmlFor={'change-dp'}
							className={
								'absolute right-2 top-0 cursor-pointer rounded-full border border-gray-200 bg-white p-1 duration-200 hover:border-primary'
							}
							title="Change Profile Picture"
						>
							<RiImageEditLine size={15} />
						</label>

						{user?.dp ? (
							<div
								className={
									'absolute bottom-0 right-2 cursor-pointer rounded-full border border-gray-200 bg-white p-1 duration-200 hover:border-primary'
								}
								onClick={handleDeleteDp}
								title="Delete Profile Picture"
							>
								<IoTrashOutline
									size={15}
									color={colors.red}
								/>
							</div>
						) : null}

						<input
							type="file"
							id={'change-dp'}
							className={'hidden'}
							accept="image/*"
							onChange={handleUploadDp}
						/>
					</div>
				</div>
			</div>

			<Divider />

			<div className={'grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-0'}>
				<div className={'flex flex-col gap-1'}>
					<span className={'text-xl font-bold text-black-7 dark:text-white'}>Personal Information</span>
					<span className={'font-medium text-black-3 dark:text-[#8a8990]'}>Change your identity information</span>
					<Button
						type={'primary'}
						className={'mt-4 hidden w-fit font-medium md:inline-flex'}
						onClick={() => nameForm.submit()}
						loading={loading.updateName}
						disabled={loading.updateName}
					>
						Update
					</Button>
				</div>

				<div>
					<Form
						layout={'vertical'}
						initialValues={{
							name: user && user.name,
							email: user && user.email,
						}}
						onFinish={(values) => handleUpdateName(values)}
						form={nameForm}
						requiredMark={false}
					>
						<Form.Item
							name={'name'}
							label={<div className="font-medium text-black-7 dark:text-white">Name</div>}
							rules={[
								{
									required: true,
									message: 'Please enter your name',
								},
							]}
						>
							<Input
								placeholder="Your name"
								className="h-9"
							/>
						</Form.Item>
						<Form.Item
							name={'email'}
							label={
								<div className="flex items-center gap-2">
									<div className="font-medium text-black-7 dark:text-white">Email</div>
									<div className="flex items-center gap-1 rounded border border-[#66D171] bg-[#D3FFD8] px-1 py-0.5 text-sm dark:bg-dark-bg">
										<IoIosCheckmarkCircle
											size={16}
											color="#66D171"
										/>
										<span className={'font-medium text-[#66D171]'}>Verified</span>
									</div>
								</div>
							}
						>
							<Input
								readOnly
								className="h-9"
							/>
						</Form.Item>

						<Button
							type={'primary'}
							className={'w-fit font-medium md:hidden'}
							onClick={() => nameForm.submit()}
							loading={loading.updateName}
							disabled={loading.updateName}
						>
							Update
						</Button>
					</Form>
				</div>
			</div>

			<Divider />

			{user?.auth_type === 'email' && (
				<div className={'grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-0'}>
					<div className={'flex flex-col gap-2'}>
						<div className={'text-xl font-bold text-black-7 dark:text-white'}>Security</div>
						<div className={'font-medium text-black-3 dark:text-[#8a8990]'}>
							Last Changed: {user ? dayjs(user.last_password_reset).fromNow() : null}
						</div>
						<Button
							type={'primary'}
							className={'mt-4 hidden w-fit font-medium md:inline-flex'}
							onClick={() => {
								changePasswordForm.submit();
							}}
							loading={loading.updatePassword}
							disabled={loading.updatePassword}
						>
							Update
						</Button>
					</div>
					<div>
						<Form
							layout={'vertical'}
							form={changePasswordForm}
							requiredMark={false}
							onFinish={(values) => {
								handleChangePassword(values);
							}}
						>
							<Form.Item
								name={'old_password'}
								label={<div className="font-medium text-black-7 dark:text-white">Current Password</div>}
								rules={[
									{
										required: true,
										message: 'Please enter your current password',
									},
								]}
								hasFeedback
							>
								<Input.Password
									className="h-9"
									placeholder="Enter your current password"
								/>
							</Form.Item>
							<Form.Item
								name={'new_password'}
								label={<div className="font-medium text-black-7 dark:text-white">New Password</div>}
								rules={[
									{
										required: true,
										message: 'Please enter your new password',
									},
								]}
								hasFeedback
							>
								<Input.Password
									className="h-9"
									placeholder="Enter your new password"
								/>
							</Form.Item>
							<Form.Item
								name={'confirm_new_password'}
								label={<div className="font-medium text-black-7 dark:text-white">Confirm New Password</div>}
								dependencies={['new_password']}
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Please confirm your password!',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('new_password') === value) {
												return Promise.resolve();
											}
											return Promise.reject(new Error('The new password that you entered do not match!'));
										},
									}),
								]}
							>
								<Input.Password
									className="h-9"
									placeholder="Confirm your new password"
								/>
							</Form.Item>

							<Button
								type={'primary'}
								className={'w-fit font-medium md:hidden'}
								onClick={() => {
									changePasswordForm.submit();
								}}
								loading={loading.updatePassword}
								disabled={loading.updatePassword}
							>
								Update
							</Button>
						</Form>
					</div>
				</div>
			)}

			{user?.auth_type === 'google' && (
				<div className="flex justify-between gap-4">
					<div className={'text-xl font-bold text-black-7 dark:text-white'}>Connected Account</div>

					<Button
						size="large"
						className="h-11 font-bold text-black-7 dark:text-white"
						icon={<FcGoogle size={20} />}
						shape="round"
					>
						Google
					</Button>
				</div>
			)}

			{user?.auth_type === 'github' && (
				<div className="flex justify-between gap-4">
					<div className={'text-xl font-bold text-black-7 dark:text-white'}>Connected Account</div>

					<Button
						size="large"
						className="h-11 font-bold text-black-7 dark:text-white"
						icon={<FaGithub size={20} />}
						shape="round"
					>
						Github
					</Button>
				</div>
			)}

			{/* <Divider /> */}

			{/* <div className={'flex items-center justify-between gap-2'}>
				<div className={'flex flex-col'}>
					<span className={'text-lg font-bold text-red-500'}>Danger Zone</span>
					<span className={'font-medium text-black-3'}>Proceed with Caution! Deleted account cannot be retrieved.</span>
				</div>
				<Button
					type={'primary'}
					danger={true}
					onClick={() => setShowDeleteModal(true)}
					className="h-9 font-semibold"
				>
					Delete Account
				</Button>
			</div> */}

			{/* <Modal
				open={showDeleteModal}
				okText={'Delete Account'}
				okButtonProps={{
					danger: true,
					disabled: !allowDelete,
					className: 'font-medium',
					onClick: () => {
						setShowDeleteModal(false);
						toast.success('This feature is not available yet.');
					},
				}}
				cancelButtonProps={{
					className: 'font-medium',
				}}
				title={'Delete Account'}
				onCancel={() => {
					setShowDeleteModal(false);
					setAllowDelete(false);
				}}
				centered
				destroyOnClose
			>
				<div className={'flex flex-col gap-2 font-medium text-black-3'}>
					<span>All resources for your account will stop working immediately. This action cannot be undone.</span>
					<span>Are you sure you want to delete your account?</span>
					<span>
						Type <span className={'text-red-500'}>sudo delete my account</span> below to confirm.
					</span>

					<Input
						onChange={(e) => {
							if (e.target.value === 'sudo delete my account') {
								setAllowDelete(true);
							} else {
								setAllowDelete(false);
							}
						}}
					/>
				</div>
			</Modal> */}

			<div className="pb-10" />
		</AppLayout>
	);
};

export default Profile;
