import { Button, Form, Input, Modal } from 'antd';
import axios from '../../config/axios';
import { useMutation } from '@tanstack/react-query';
import { handleAPIError } from '../../utils/helpers';
import { Workspace } from '../../layouts/sidebar-bottom';
import { toast } from 'react-hot-toast';

type Props = {
	open: boolean;
	close: () => void;
	workspace: Workspace;
	refetch: () => void;
};

type FormValues = {
	name: string;
};

const UpdateWorkspaceModal = (props: Props) => {
	const { open, close, workspace, refetch } = props;

	// get the default workspace from local storage
	const selectedWorkspace: Workspace | null = JSON.parse(localStorage.getItem('default_workspace') ?? 'null');

	const { mutate: updateWorkspace, isPending: isUpdatingWorkspace } = useMutation({
		mutationFn: async (values: FormValues) => {
			const { data } = await axios.patch(`/workspaces/${workspace.workspace_id}`, values, { withCredentials: true });
			return data.results;
		},
		onSuccess: (data) => {
			// update the default workspace in local storage
			if (selectedWorkspace?.workspace_id === workspace.workspace_id) {
				localStorage.setItem('default_workspace', JSON.stringify(data));
			}

			// refetch the workspaces
			refetch();

			// close the modal
			close();

			// show success toast
			toast.success('Workspace updated successfully');
		},
		onError: handleAPIError,
	});

	return (
		<Modal
			open={open}
			onCancel={close}
			title="Update Workspace"
			footer={null}
			centered
			width={400}
			destroyOnClose
		>
			<Form
				layout="vertical"
				onFinish={(values) => updateWorkspace(values)}
				initialValues={{
					name: workspace.name,
				}}
			>
				<Form.Item
					name="name"
					label={<div className="font-semibold">Workspace Name</div>}
					className="mb-4"
				>
					<Input
						required
						placeholder="Workspace Name"
						maxLength={100}
						showCount
					/>
				</Form.Item>
				<Form.Item className="mb-0 flex justify-end">
					<div className="flex gap-2">
						<Button onClick={close}>Cancel</Button>
						<Button
							type="primary"
							htmlType="submit"
							loading={isUpdatingWorkspace}
							disabled={isUpdatingWorkspace}
						>
							Update
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default UpdateWorkspaceModal;
