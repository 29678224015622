import { nanoid } from 'nanoid';
import { FC, useEffect } from 'react';
import { useToolsStore } from '../../store/tools';
import { AIAssistant } from '../../types/ai-assistant.types';
import Tools from '../tools/base/tools';

type AssistantToolsProps = {
	assistant: AIAssistant;
	refetchAssistant: () => void;
};

const AssistantTools: FC<AssistantToolsProps> = (props) => {
	const { setTools } = useToolsStore();

	useEffect(() => {
		const tools = props.assistant.tools || [];
		const toolsWithId = tools.map((tool) => ({ ...tool, id: nanoid() }));
		setTools(toolsWithId);
	}, [props.assistant, setTools, props.refetchAssistant]);

	return (
		<Tools
			for="assistant"
			refetchAssistant={props.refetchAssistant}
		/>
	);
};

export default AssistantTools;
