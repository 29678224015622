import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { cn } from '../../config/cn';

interface FilterSectionProps {
	title: string;
	children: React.ReactNode;
}

const FilterSection: React.FC<FilterSectionProps> = ({ title, children }) => {
	const [isExpanded, setIsExpanded] = useState(true);

	return (
		<div className={cn('mb-4', { 'mb-2': !isExpanded })}>
			<button
				onClick={() => setIsExpanded(!isExpanded)}
				className="group flex w-full items-center justify-between"
			>
				<div className="text-lg font-semibold text-gray-900">{title}</div>
				{isExpanded ? <BsChevronUp /> : <BsChevronDown />}
			</button>

			<div className="pt-3" />

			<AnimatePresence>
				{isExpanded && (
					<motion.div
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: 'auto', opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.2 }}
					>
						{children}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default FilterSection;
